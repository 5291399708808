import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    authors(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/authors', {})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    list(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/articles', {params: queryParams})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    show(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/articles/${id}`)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    delete(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/articles/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    store(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/articles`, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    update(ctx, {id, data}) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/articles/${id}`, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
  },
}
