import store from '@/store'
import { reactive, ref, watch } from '@vue/composition-api'
import { useToast } from "vue-toastification/composition";
import _ from 'lodash'
import {useRouter} from '@core/utils'
import axios from "@axios";

export default function useArticles() {
  const deleteConfirmState = reactive({
    id: null,
    visible: false,
  })

  const articlesListTable = ref([])
  const categories = ref([])
  const rawCategories = ref([])
  const sections = ref([])
  const authors = ref([])
  const {router} = useRouter()

  const articleData = ref({});
  const seoData = ref({});
  const bodyHtml = ref(null);

  // Table Handlers
  const tableColumns = [
    { text: '#ID', align: 'start', value: 'id', sortable: false },
    { text: 'IMAGE', align: 'start', value: 'image', sortable: false },
    { text: 'NAME', value: 'name', sortable: false },
    { text: 'PUBLISHED', value: 'is_published', sortable: false },
    { text: 'SLUG', value: 'slug', sortable: false },
    { text: 'AUTHOR', value: 'author.name', sortable: false },
    { text: 'CATEGORY', value: 'category.name', sortable: false },
    { text: 'UPDATED', value: 'updated_at', sortable: false },
    { text: '', value: 'actions', align: 'center', sortable: false },
  ]

  const searchQuery = ref('')
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const totalArticleListTable = ref(0)
  const loading = ref(false)
  const statusFilter = ref(null)
  const section_id = ref(null)
  const selectedTableData = ref([])
  const errorMessages = ref([])

  const toast = useToast()

  const getCategories = () => {
    store
      .dispatch('app-content-categories/list', {
      })
      .then(response => {
        rawCategories.value = response.data
      })
      .catch(error => {
        console.log(error)
        errorMessages.value = error.response.data.errors
      })
  }

  const getSections = () => {
    store
      .dispatch('app-content-sections/list', {
      })
      .then(response => {
        sections.value = response.data
      })
      .catch(error => {
        console.log(error)
        errorMessages.value = error.response.data.errors
      })
  }

  const getAuthors = () => {
    store
      .dispatch('app-content-articles/authors', {
      })
      .then(response => {
        authors.value = response.data
      })
      .catch(error => {
        console.log(error)
        errorMessages.value = error.response.data.errors
      })
  }

  const deleteArticle = id => {
    store
      .dispatch('app-content-articles/delete', {
        id: id,
      })
      .then(response => {
        fetchArticles()
      })
      .catch(error => {
        console.log(error)
        errorMessages.value = error.response.data.errors
        for (const key in error.response.data.errors) {
          toast.warning(error.response.data.errors[key][0])
        }
      })
  }

  const toggleArticlePublishState = (id, state) => {
    store
      .dispatch('app-content-articles/update', {
        id: id,
        data: {
          is_published: state,
        },
      })
      .catch(error => {
        console.log(error)
        errorMessages.value = error.response.data.errors
        for (const key in error.response.data.errors) {
          toast.warning(error.response.data.errors[key][0])
        }
      })
  }

  const updateArticle = (id, data, showError = false) => {
    store
      .dispatch('app-content-articles/update', {
        id: id,
        data: data,
      })
      .then(() => {
        toast.success('Updated')
      })
      .catch(error => {
        console.log(error)
        errorMessages.value = error.response.data.errors
        if (showError) {

          for (const key in error.response.data.errors) {
            toast.warning(error.response.data.errors[key][0])
          }
        } else {
          toast.warning('Validation errors')
        }
      })
  }

  const storeArticle = (data) => {
    store
      .dispatch('app-content-articles/store', data)
      .then(response => {
        toast.success('Created!')
        router.push({ name: 'content-articles-edit', params: { id: response.data.id } })
      })
      .catch(error => {
        console.log(error)
        errorMessages.value = error.response.data.errors
        for (const key in error.response.data.errors) {
          toast.warning(error.response.data.errors[key][0])
        }
      })
  }

  const updateOneProperty = (id, name, value) => {
    updateArticle(id, {
      [name]: value,
    }, true)
  }

  const fetchArticle = (id) => {
    store
      .dispatch('app-content-articles/show', {
        id: id,
      })
      .then(response => {
        articleData.value = response.data
        seoData.value = response.data.seo
        bodyHtml.value = response.data.body
        section_id.value = response.data.category.section_id
      })
      .catch(error => {
        console.log(error)
        errorMessages.value = error.response.data.errors
      })
  }

  const makeArticle = () => {
    const template = {
      language: 'en',
      is_recommended: false,
      category_id: null,
      author_id: null,
      teaser_image: null,
      seo: {
        title: null,
      },
    }

    articleData.value = template
    seoData.value = template.seo
  }

  const fetchArticles = () => {
    store
      .dispatch('app-content-articles/list', {
        q: searchQuery.value,
        options: options.value,
        status: statusFilter.value,
      })
      .then(response => {
        const filteredData = response.data
        articlesListTable.value = filteredData
        totalArticleListTable.value = filteredData.length
        loading.value = false
      })
      .catch(error => {
        console.log(error)
        errorMessages.value = error.response.data.errors
      })
  }

  const tinyOptions  = {
    toolbar1: 'formatselect | bold italic strikethrough forecolor backcolor | link image | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat code',
    height: 400,
    image_caption: true,
    image_title: true,
    image_dimensions: true,

    images_upload_url: process.env.VUE_APP_API_BASE_URL + '/uploader',
    images_upload_handler: function (blobInfo, success, failure) {
      let formData = new FormData();
      formData.append('file', blobInfo.blob(), blobInfo.filename());
      axios.post(process.env.VUE_APP_API_BASE_URL + '/uploader', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then(function (response) {
        success(response.data)
      })
    },
  }

  watch([searchQuery, statusFilter, options], () => {
    // start loading
    loading.value = true
    selectedTableData.value = []
    fetchArticles()
  })

  watch([section_id], () => {
    categories.value = _.filter(rawCategories.value, (o) => o.section_id === section_id.value )
  })

  return {
    section_id,
    authors,
    categories,
    sections,
    bodyHtml,
    tableColumns,
    searchQuery,
    options,
    articleData,
    seoData,
    articlesListTable,
    statusFilter,
    totalArticleListTable,
    loading,
    selectedTableData,
    deleteConfirmState,
    errorMessages,
    toast,
    tinyOptions,
    getAuthors,
    getCategories,
    getSections,
    makeArticle,
    storeArticle,
    fetchArticles,
    fetchArticle,
    deleteArticle,
    updateArticle,
    updateOneProperty,
    toggleArticlePublishState,
  }
}
